import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faDiscord,
  faTwitter,
  faFacebook,
  faTelegram,
  faMedium,
  faReddit,
} from "@fortawesome/free-brands-svg-icons";
import { faLock } from '@fortawesome/free-solid-svg-icons';

export default function Footer() {
  const intsagramURL = "https://www.instagram.com/publishednft/";
  const twitterUrl = "https://twitter.com/publishednft";
  const facebookUrl = "https://www.facebook.com/Published-NFT-111210031197860";
  const telegramURL = "https://t.me/published_nft";
  const discordURL =
    "https://discord.com/channels/854792215577755679/854792217428361279";
  const mediumURL =
    "https://publishednft.medium.com/published-nft-limited-pre-sale-nft-release-sale-d23776faa232";
  const privacyURL =
    "https://docs.google.com/file/d/17BcbMg-5hY4U6317x5XYd7ETC3-C5hxM/edit?usp=docslist_api&filetype=msword";
  const redditURL = "https://www.reddit.com/user/PublishedNFT";
  const openseaURL = "https://opensea.io/PublishedNFT";
  const contactURL = "publishednft@gmail.com";
  const year = new Date().getFullYear();
  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 768;

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  return (
    <>
      <div className="row bg-black p-5">
        <div className="col-md-3 flex">
          <a
            className="me-3 text-white"
            href={intsagramURL && intsagramURL}
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a
            className="me-3 text-white"
            href={discordURL && discordURL}
            target="_blank"
            rel="noreferrer"
          >
            <FontAwesomeIcon icon={faDiscord} />
          </a>
          <a
            className="me-3 text-white"
            href={twitterUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a
            className="me-3 text-white"
            href={telegramURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faTelegram} />
          </a>
          <a
            className="me-3 text-white"
            href={facebookUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faFacebook} />
          </a>
          <a
            className="me-3 text-white"
            href={mediumURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faMedium} />
          </a>
          <a
            className="me-3 text-white"
            href={redditURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faReddit} />
          </a>
          {/* <a
      className="me-3 text-white"
      href={openseaURL}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        src="https://storage.googleapis.com/opensea-static/Logomark/Logomark-Transparent%20White.svg"
        alt=""
        width="16px"
        height="16px"
        style={{ marginBottom: "2px" }}
      />
    </a> */}

          <a
            className="me-3 text-white  pb-1"
            href="https://published-nft-organization.gitbook.io/published-nft-faq/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src="/gitbook-svg.svg"
              width="16px"
              height="16px"></img>
          </a>
        </div>

        <div className="col-md-9">
          <div className="row mt-3">
            {isMobile ? (
              <>
                <div className="row mb-3">
                  <div className="col-6 d-flex justify-content-center">
                    <a href="https://nft.flowverse.co/marketplace/PublishedNFT?tab=items" target="_blank">
                      <img
                        className="me-3"
                        alt=""
                        src="/flowverse.png"
                        width="50"
                        height="50"
                      />
                    </a>
                  </div>

                  <div className="col-6 d-flex justify-content-center" target="_blank">
                    <a
                      className="col-md-2 text-center"
                      href="https://matrixmarket.xyz/collection/mainnet_flow-A.440776a8205e9f80.PublishedNFT"
                      target="_blank"
                    >
                      <img className="me-3" alt="" src="/matrix_market_logo2.png" width="60" height="60" />
                    </a>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-6 d-flex justify-content-center">
                    <a href="https://evaluate.xyz/the-published-nft-collection/collections" target="_blank">
                      <img
                        alt=""
                        src="/evaluate_green_1.png"
                        width="50"
                        height="40"
                      />
                    </a>
                  </div>

                  <div className="col-6 d-flex justify-content-center">
                    <a href="https://shadow.is/A.440776a8205e9f80.PublishedNFT" target="_blank">
                      <img
                        alt=""
                        src="/shadow_logo.png"
                        width="50"
                        height="40"
                      />
                    </a>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-6 d-flex justify-content-center">
                    <a
                      className="col-md-2 text-center"
                      href="https://find.xyz/publishednft"
                      target="_blank"
                    >
                      <img className="me-3" alt="" src="/find-logo.png" width="80" height="30" />
                    </a>
                  </div>
                  <div className="col-6 d-flex justify-content-center">
                    <a
                      className="col-md-2 text-center"
                      href="https://toucans.ecdao.org/p/PublishedNFTDAO"
                      target="_blank"
                    >
                      <img className="me-3" alt="" src="/toucans-logo.png" width="45" height="45" />
                    </a>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col-6 d-flex justify-content-center">
                    <a
                      className="col-md-2 text-center"
                      href="https://www.flowdiver.io/account/0x440776a8205e9f80"
                      target="_blank"
                    >
                      <img className="me-3" alt="" src="/flowdiver.png" width="45" height="45" />
                    </a>
                  </div>
                </div>
              </>
            ) : (
              <div className="col-md-7">
                <div className="row">
                  <div className="col">
                    <a
                      className="text-center"
                      href="https://nft.flowverse.co/marketplace/PublishedNFT?tab=items"
                      target="_blank"
                    >
                      <img className="me-3" alt="" src="/flowverse.png" width="50" height="50" />
                    </a>
                  </div>
                  <div className="col">
                    <a
                      className="text-center"
                      href="https://matrixmarket.xyz/collection/mainnet_flow-A.440776a8205e9f80.PublishedNFT"
                      target="_blank"
                    >
                      <img className="me-3" alt="" src="/matrix_market_logo2.png" width="60" height="60" />
                    </a>
                  </div>
                  <div className="col">
                    <a
                      className="text-center"
                      href="https://evaluate.xyz/the-published-nft-collection/collections"
                      target="_blank"
                    >
                      <img className="me-3" alt="" src="/evaluate_green_1.png" width="50" height="44" />
                    </a>
                  </div>
                  <div className="col">
                    <a
                      className="text-center"
                      href="https://shadow.is/A.440776a8205e9f80.PublishedNFT"
                      target="_blank"
                    >
                      <img className="me-3" alt="" src="/shadow_logo.png" width="50" height="40" />
                    </a>
                  </div>
                  <div className="col">
                    <a
                      className="text-center"
                      href="https://find.xyz/publishednft"
                      target="_blank"
                    >
                      <img className="me-3" alt="" src="/find-logo.png" width="80" height="30" />
                    </a>
                  </div>
                  <div className="col">
                    <a
                      className="text-center"
                      href="https://toucans.ecdao.org/p/PublishedNFTDAO"
                      target="_blank"
                    >
                      <img className="" alt="" src="/toucans-logo.png" width="45" height="45" />
                    </a>
                  </div>
                  <div className="col">
                    <a
                      className="text-center"
                      href="https://www.flowdiver.io/account/0x440776a8205e9f80"
                      target="_blank"
                    >
                      <img className="" alt="" src="/flowdiver.png" width="45" height="45" />
                    </a>
                  </div>
                </div>
              </div>
            )}

            <div className="col-md-5">
              <div className="row">
                <div className="col-md-4">
                  <a
                    className="text-center"
                    href="https://app.increment.fi/swap?in=A.1654653399040a61.FlowToken&out="
                    target="_blank"
                  >
                    <img className="" alt="" src="/increment_logo_2.png" width="45" height="45" />
                  </a>
                </div>

                <div className="col-md-4">
                  <a
                    className="text-center"
                    href="https://www.flowty.io/collection/0x440776a8205e9f80/PublishedNFT/activity/rentals "
                    target="_blank"
                  >
                    <img className="me-1" alt="" src="/flowty.png" width="150" height="45" />
                  </a>
                </div>

                <div className="col-md-4">
                  <a href="https://www.meetdapper.com/shop" target="_blank">
                    <p className="mt-2 mb-0 font-sm text-end text-white">
                      <FontAwesomeIcon icon={faLock} />&nbsp; Secured by <b>Dapper</b>
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-md-2 p-2">
              <Link
                className="text-decoration-none me-4"
                to="/terms"
              >
                Terms Of Service
              </Link>
            </div>

            <div className="col-md-2 p-2">
              <Link
                className="text-decoration-none me-4"
                to="/privacypolicy"
              >
                Privacy Policy
              </Link>
            </div>

            <div className="col-md-2 p-2">
              <a
                className="text-decoration-none me-4"
                href="https://discord.gg/K9AtXba9H9"
                target="_blank"
                rel="noreferrer"
              >
                Contact Us
              </a>
            </div>

            <div className="col-md-2 p-2">
              <Link className="text-decoration-none me-4" to="/about">
                About
              </Link>
            </div>

            <div className="col-md-2 p-2">
              <a
                className="text-decoration-none"
                href="https://app.gitbook.com/invite/R7FxhRYcHCFMvDHx5KPk/qoXISetWMcQQdLkUKb3K"
                target="_blank"
              >
                FAQ
              </a>
            </div>

          </div>
        </div>

        <div className="text-center mt-5">
          <p className="text-white pb-2 mx-12">
            All rights reserved. Published NFT™ is a trademark of Published NFT
            LLC.
          </p>
          <p className="mx-12">{`©2024`} Published NFT LLC.</p>
        </div>

        <div className="d-flex justify-content-center">
          <div className="m-auto">
            <a
              className="text-center"
              href="https://www.onflow.org/post/partner-spotlight-publish-ebooks-as-nfts-with-published-nft"
              target="_blank"
            >
              <img
                className="me-3"
                alt=""
                src="/FLOW_BANNER_CLEAN.gif"
                width="250"
                height="40"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
}
